class GetValidationErrorMessageHandler {
    handle(validation, serverErrors, key, index) {
        const item = this._getItem(validation, key, index);
        const serverError = this._getServerError(serverErrors, key, index);

        if (serverError) {
            return serverError;
        }

        if (!item || !item.$error) {
            return '';
        }

        const { $params } = item;

        for (const key in $params) {
            if (!item[key]) {
                return this.errors[key] || 'Something is wrong... 🤔';
            }
        }

        return 'Something is wrong... 🤔';
    }

    _getItem(validation, key, index) {
        if (isNaN(index)) {
            return validation[key];
        }

        return validation.$each[index][key];
    }

    _getServerError(serverErrors, key, index) {
        if (!isNaN(index)) {
            // @TODO
            return '';
        }

        if (serverErrors.length) {
            const errorItem = serverErrors.find(error => error.param === key);

            if (errorItem) {
                return errorItem.message;
            }
        }

        return '';
    }

    get errors() {
        return {
            required: 'אסור להשאיר ריק!',
            isFormatValid: 'סוג קובץ לא חוקי!',
            email: 'כתובת דוא"ל לא תקינה!',
            atLeastOneItem: 'Please add at least one item!',
            isFullName:
                'Full name must contain two words with at least two letters each.',
            maxElements: 'קצת הגזמת!',
            size: 'הקובץ שבחרת גדול מדי!',
            sameAs: 'המספרים לא תואמים',
            minLength: 'מלל קצר מדי!',
            isPhoneValid: 'פורמט שגוי!'
        };
    }
}

export default GetValidationErrorMessageHandler;
