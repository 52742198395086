<template>
    <div v-if="$showModal('login')" class="custom_popup_wrapper">
        <div class="custom_popup_inner">
            <div
                :id="modalId"
                v-click-outside="close"
                class="b_modal xs_size mfp-hide zoom-anim-dialog"
                :class="{ hero_image: !loggedSuccessfully }"
            >
                <div v-if="!loggedSuccessfully" class="modal_content_inner">
                    <tz-form
                        v-if="!otpSent && errorMessage"
                        @go-back="displayPhoneForm"
                        @open-sign-up-form="openSignUpForm"
                    />
                    <phone-form
                        v-else-if="!otpSent"
                        v-model="phone"
                        :error-message="errorMessage"
                        :is-processing="isProcessing"
                        @send-otp="sendOtp"
                        @open-sign-up-form="openSignUpForm"
                    />
                    <otp-form
                        v-else
                        v-model="otp"
                        :error-message="errorMessage"
                        :is-processing="isProcessing"
                        @send-otp="sendOtp"
                        @send-otp-email="sendOtp"
                        @login="handleLogin"
                    />

                    <recaptcha-message />
                </div>

                <logged-successfully v-else @submit="submit" @close="close" />

                <button
                    title="Close (Esc)"
                    type="button"
                    class="mfp-close"
                    @click="close"
                >
                    ×
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { StatusCodes } from 'http-status-codes';
import TZForm from '@/components/auth/TZ';
import OTPForm from '@/components/auth/OTP';
import PhoneForm from '@/components/auth/Phone';
import recaptchaMixin from '@/mixins/recaptcha.mixin.js';
import LoggedSuccessfully from '@/components/auth/LoggedSuccessfully';

export default {
    components: {
        PhoneForm,
        'tz-form': TZForm,
        'otp-form': OTPForm,
        LoggedSuccessfully
    },

    mixins: [recaptchaMixin],

    data() {
        return {
            phone: '',
            otp: '',
            redirect: '',
            otpSent: false,
            errorMessage: '',
            baseErrorMessage: 'משהו השתבש',
            isProcessing: false,
            loggedSuccessfully: false
        };
    },

    computed: {
        ...mapGetters({
            loggedUser: 'loggedUser',
            selectedCampus: 'campuses/selectedCampus'
        }),

        modalId() {
            if (this.loggedSuccessfully) {
                return 'registered_successfully';
            }

            if (this.otpSent) {
                return 'login_code';
            }

            if (this.errorMessage) {
                return 'unsuccessful_login';
            }

            return 'login';
        }
    },

    created() {
        this.redirect = this.$route.query?.redirect;
    },

    methods: {
        async sendOtp({ resend = false, email = false } = {}) {
            if (!this.phone) {
                this.errorMessage = 'Phone number is required.';

                return;
            }

            const endpont = email ? '/auth/otp-email' : '/auth/otp';

            this.isProcessing = true;

            try {
                const token = await this.$recaptcha.execute('otp');

                await this.$axios.post(endpont, {
                    phone: this.phone,
                    _token: token
                });

                this.otpSent = true;
                this.errorMessage = '';

                if (resend) {
                    this.$toasted.success('קוד חד פעמי נשלח');
                }

                if (email) {
                    this.$toasted.success('קוד חד פעמי נשלח דרך האימייל');
                }
            } catch (err) {
                console.error(err);

                this.$sentry.captureException(err);

                const { response } = err;

                if (response) {
                    const { status, data } = response;

                    if (status === StatusCodes.BAD_REQUEST) {
                        this.errorMessage = data.errors
                            .map(error => error.message)
                            .join('. ');
                    }

                    if (status === StatusCodes.NOT_FOUND) {
                        this.errorMessage = 'Phone number does not exist.';
                    }

                    if (
                        status === StatusCodes.FORBIDDEN ||
                        status === StatusCodes.UNPROCESSABLE_ENTITY
                    ) {
                        this.errorMessage = data || this.baseErrorMessage;
                    }

                    if (status === StatusCodes.UNAUTHORIZED) {
                        this.errorMessage = 'Invalid credentials.';
                    }
                } else {
                    this.errorMessage = this.baseErrorMessage;
                }

                console.error(this.errorMessage);
            } finally {
                this.isProcessing = false;
            }
        },

        async handleLogin() {
            if (!this.phone || !this.otp) {
                this.errorMessage = 'OTP is required.';

                return;
            }

            this.isProcessing = true;

            try {
                const token = await this.$recaptcha.execute('login');

                await this.$auth.loginWith('local', {
                    data: {
                        phone: this.phone,
                        otp: this.otp,
                        _token: token
                    }
                });

                this.$toasted.success('התחברת');

                const redirect = this.$auth.$storage.getCookie('redirect');

                if (redirect) {
                    this.$router.push(redirect);
                    this.$auth.$storage.removeCookie('redirect');
                }

                if (this.redirect) {
                    this.$router.push(this.redirect);
                }

                this.resetForm();

                this.loggedSuccessfully = true;

                this.$gtm.push({
                    event: 'campus',
                    campusId: this.selectedCampus.id,
                    userId: this.loggedUser.id
                });
            } catch (err) {
                console.error(err);

                this.$sentry.captureException(err);

                const { response } = err;

                if (response) {
                    const { status } = response;

                    if (status === StatusCodes.UNAUTHORIZED) {
                        this.errorMessage = 'Invalid credentials.';
                    }
                } else {
                    this.errorMessage = this.baseErrorMessage;
                }

                console.error(this.errorMessage);
            } finally {
                this.isProcessing = false;
            }
        },

        submit() {
            this.loggedSuccessfully = false;

            this.$router.push('/personal-zone/account');

            this.close();
        },

        close() {
            this.loggedSuccessfully = false;

            this.redirect = '';

            this.$auth.$storage.removeCookie('redirect');

            this.resetForm();

            this.$closeModal();
        },

        resetForm() {
            this.phone = '';
            this.otp = '';
            this.otpSent = false;
            this.errorMessage = '';
        },

        displayPhoneForm() {
            this.errorMessage = '';
            this.otpSent = false;
        },

        openSignUpForm() {
            this.close();

            this.$openModal('sign-up');
        }
    }
};
</script>
