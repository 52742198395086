'use strict';

export const defaultState = () => ({
    CREATED: {
        value: 'created',
        text: 'נוצרה'
    },
    PAID: {
        value: 'paid',
        text: 'שולם'
    },
    SENT: {
        value: 'sent',
        text: 'נשלחה'
    },
    SHIPPING: {
        value: 'shipping',
        text: 'בדרך'
    },
    ARRIVED: {
        value: 'arrived',
        text: 'הגיע'
    },
    COLLECTED: {
        value: 'collected',
        text: 'נאספה'
    },
    REFUNDED: {
        value: 'refunded',
        text: 'עסקה זוכתה'
    },
    CANCELLED: {
        value: 'cancelled',
        text: 'בּוּטלָה'
    }
});

export const state = () => defaultState();

export const getters = {
    ALL_STATUSES: state => [
        state.CREATED,
        state.PAID,
        state.SENT,
        state.SHIPPING,
        state.ARRIVED,
        state.COLLECTED,
        state.REFUNDED,
        state.CANCELLED
    ]
};

export const mutations = {};

export const actions = {
    index(ctx, { page = 1, perPage = 10 }) {
        const params = {
            page,
            perPage
        };

        return this.$axios.$get('/orders', {
            params
        });
    },

    show(ctx, id) {
        return this.$axios.$get(`/orders/${id}`);
    },

    create(ctx, orderData) {
        return this.$axios.$post('/orders', orderData);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
