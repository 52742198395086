<template>
    <component :is="name" v-if="ad" :key="updateKey" :ad="ad" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AboveFooter from '@/components/ads/AboveFooter';
import HomepageTile from '@/components/ads/HomepageTile';
import HomepageBalloon from '@/components/ads/HomepageBalloon';
import AcademicBankTile from '@/components/ads/AcademicBankTile';

export default {
    components: {
        AboveFooter,
        HomepageTile,
        HomepageBalloon,
        AcademicBankTile
    },

    props: {
        name: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            updateKey: 0
        };
    },

    computed: {
        ...mapGetters({
            getAdByName: 'ads/getByName',
            selectedCampus: 'campuses/selectedCampus'
        }),

        ad() {
            return this.getAdByName(this.name);
        }
    },

    watch: {
        async selectedCampus() {
            if (!this.selectedCampus) {
                return;
            }

            await this.getAd();

            this.updateKey++;
        }
    },

    async mounted() {
        await this.getAd();
    },

    methods: {
        ...mapActions({
            fetchAd: 'ads/fetch'
        }),

        async getAd() {
            try {
                await this.fetchAd(this.name);
            } catch (err) {
                console.error(err);

                this.$sentry.captureException(err);
            }
        }
    }
};
</script>
