<template>
    <div class="association_item">
        <a
            :href="ad.link"
            target="_blank"
            class="association_block text_center banner"
            @click="onClick"
        >
            <div
                v-full-background-image-path="ad.image"
                class="a_banner bg_image cover"
            />

            <div class="block_image">
                <div class="image_wrapper" />

                <div class="block_title second_font">
                    {{ ad.title }}
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import BaseAd from '@/components/ads/Base';

export default {
    extends: BaseAd
};
</script>
