<template>
    <div class="menus_wrapper">
        <div class="categories_wrapper">
            <div
                v-for="(menu, menuIndex) in selectedMenu.subMenus"
                :key="menuIndex"
                class="category_menu"
            >
                <div class="category_title second_font">
                    {{ menu.title }}
                </div>
                <div class="menu">
                    <ul>
                        <li
                            v-for="(item, itemIndex) in menu.items"
                            :key="itemIndex"
                        >
                            <menu-link :link="item" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="custom_menu">
            <ul>
                <li
                    v-for="(link, linkIndex) in selectedMenu.links"
                    :key="linkIndex"
                >
                    <menu-link :link="link" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import MenuLink from './Link';

export default {
    components: {
        MenuLink
    },

    props: {
        selectedMenu: {
            type: Object,
            required: true
        }
    }
};
</script>
