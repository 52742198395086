import { StatusCodes } from 'http-status-codes';

export default async function({ store: { dispatch }, $gtm }) {
    try {
        const key = await dispatch('settings/show', 'gtmKey');

        if (!key) {
            console.warn('GTM key not found.');

            return;
        }

        $gtm.init(key);
    } catch (error) {
        if (error.response && error.response.status === StatusCodes.NOT_FOUND) {
            console.warn('GTM key not found.');

            return;
        }

        console.error(error);
    }
}
