'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(ctx, { page = 1, perPage = 7, search = '' }) {
        const params = {
            page,
            perPage
        };

        if (search) {
            params.q = search;
        }

        return this.$axios.$get('/academic-courses', { params });
    },

    show(ctx, id) {
        return this.$axios.$get(`/academic-courses/${id}`);
    },

    favoriteCourses() {
        return this.$axios.$get('/academic-courses/favorite-courses');
    },

    favorite(ctx, id) {
        return this.$axios.$post(`/academic-courses/${id}/favorite`);
    },

    subscribe(ctx, id) {
        return this.$axios.$post(`/academic-courses/${id}/subscribe`);
    },

    unsubscribe(ctx, id) {
        return this.$axios.$post(`/academic-courses/${id}/unsubscribe`);
    },

    files(ctx, { id, fileType }) {
        return this.$axios.$get(`/academic-courses/${id}/files`, {
            params: { fileType }
        });
    },

    lecturers(ctx, { id, page = 1, perPage = 7, filters = null }) {
        const params = {
            page,
            perPage
        };

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        return this.$axios.$get(`/academic-courses/${id}/lecturers`, {
            params
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
