import createPersistedState from 'vuex-persistedstate';

const prefix = 'aguda';
let currentKey = prefix;

try {
    const deployJson = require('@/deploy.json');

    currentKey = `${prefix}_${deployJson.current_timestamp}`;
} catch (e) {
    console.error(
        'File deploy.json not found. Please create this file to prevent errors'
    );
}

export default ({ store }) => {
    window.onNuxtReady(() => {
        const keys = Object.keys(window.localStorage);

        keys.forEach(key => {
            if (key.startsWith(prefix) && key !== currentKey) {
                window.localStorage.removeItem(key);
            }
        });

        createPersistedState({
            key: currentKey,
            paths: ['campuses.selectedCampus', 'cart']
        })(store);
    });
};
