var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header_section",class:{ 'is-sticky': _vm.isSticky }},[_c('div',{staticClass:"sticky-wrapper",class:{ 'is-sticky': _vm.isSticky }},[_c('header',{staticClass:"b_header"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header_wrapper flex"},[_c('div',{staticClass:"right_column flex"},[_c('div',{staticClass:"btn_menu"},[_c('button',{staticClass:"menu_toggler jsMenu",attrs:{"aria-label":"Toggle menu"},on:{"click":function($event){return _vm.setIsMenuOpened(true)}}},[_c('span',{staticClass:"toggler_top"}),_vm._v(" "),_c('span',{staticClass:"toggler_middle"}),_vm._v(" "),_c('span',{staticClass:"toggler_bottom"})])]),_vm._v(" "),_c('div',{staticClass:"logo_wrapper"},[_c('nuxt-link',{staticClass:"logo_link_mobile",attrs:{"to":"/"}},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                                'logo.svg'
                                            ),expression:"\n                                                'logo.svg'\n                                            ",arg:"assets"}]})]),_vm._v(" "),_c('nuxt-link',{staticClass:"logo_link",attrs:{"to":"/"}},[_c('div',{staticClass:"logo_icon"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                                    'logo_icon.svg'
                                                ),expression:"\n                                                    'logo_icon.svg'\n                                                ",arg:"assets"}]})]),_vm._v(" "),_c('div',{staticClass:"logo_text"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                                    'logo_text.svg'
                                                ),expression:"\n                                                    'logo_text.svg'\n                                                ",arg:"assets"}]})])])],1)]),_vm._v(" "),_c('div',{staticClass:"left_column flex"},[_c('search'),_vm._v(" "),_c('client-only',[_c('login-control')],1)],1)])])])])])]),_vm._v(" "),_c('mega-menu',{attrs:{"menu-items":_vm.content.menuItems}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }