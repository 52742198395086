export const defaultState = () => {};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(vuexContext, { q, type = 'all', offset = 0, limit = 4 }) {
        return this.$axios.$get('/search', {
            params: {
                q,
                type,
                offset,
                limit
            }
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
