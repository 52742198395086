import RecaptchaMessage from '@/components/elements/RecaptchaMessage';

export default {
    components: {
        RecaptchaMessage
    },

    async mounted() {
        try {
            await this.$recaptcha.init();
        } catch (e) {
            console.error(e);

            this.$sentry.captureException(e);
        }
    },

    beforeDestroy() {
        this.$recaptcha.destroy();
    }
};
