<template>
    <div class="b_not_found">
        <div class="container">
            <div class="error_page flex">
                <div class="block_content">
                    <div class="content second_font">
                        <h1>
                            {{ error.statusCode }}
                        </h1>

                        <p>
                            {{ content }}
                        </p>
                    </div>
                </div>

                <div class="image_wrapper">
                    <picture v-full-image-path:assets="'error_image.svg'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { StatusCodes } from 'http-status-codes';

export default {
    props: {
        error: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            codes: {
                401: 'Unauthorized',
                403: 'Forbidden',
                404: 'העמוד לא נמצא'
            }
        };
    },

    computed: {
        content() {
            return this.codes[this.error.statusCode] || 'משהו השתבש';
        }
    },

    mounted() {
        if (this.error.statusCode === StatusCodes.NOT_FOUND) {
            console.error(`404 page. Url: ${this.$route.fullPath}`);
        }
    }
};
</script>
