export const defaultState = () => ({
    footers: []
});

export const state = () => defaultState();

export const getters = {
    getFooterByCampusId: state => campusId =>
        state.footers.find(item => item.campusId === campusId)?.columns
};

export const mutations = {
    ADD_SINGLE_CAMPUS_FOOTER(state, footer) {
        state.footers = [...state.footers, footer];
    }
};

export const actions = {
    async fetchColumns({ commit, getters }, campusId) {
        let columns = getters.getFooterByCampusId(campusId);

        if (!columns) {
            columns = await this.$axios.$get(`/footer-columns/${campusId}`);

            commit('ADD_SINGLE_CAMPUS_FOOTER', { campusId, columns });
        }

        return columns;
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
