<template>
    <div class="content-wrapper">
        <app-header />
        <main>
            <nuxt />
        </main>
        <app-footer />

        <join-modal />
        <login-modal />
        <sign-up-modal />
        <reset-phone-modal />

        <client-only>
            <campus-chooser-modal />
        </client-only>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import JoinModal from '@/components/modals/Join';
import AppHeader from '@/components/layout/Header';
import AppFooter from '@/components/layout/Footer';
import LoginModal from '@/components/modals/Login';
import SignUpModal from '@/components/modals/SignUp';
import ResetPhoneModal from '@/components/modals/ResetPhone';
import CampusChooserModal from '@/components/modals/CampusChooser';

export default {
    components: {
        AppHeader,
        AppFooter,
        JoinModal,
        LoginModal,
        SignUpModal,
        ResetPhoneModal,
        CampusChooserModal
    },

    head() {
        return {
            bodyAttrs: {
                style:
                    this.isAnyModalOpen || this.isMenuOpened
                        ? 'overflow: hidden'
                        : ''
            }
        };
    },

    computed: {
        ...mapGetters({
            campuses: 'campuses/campuses',
            selectedCampus: 'campuses/selectedCampus',
            isAnyModalOpen: 'modals/blur',
            isMenuOpened: 'isMenuOpened'
        })
    },

    watch: {
        '$route.query': {
            handler(val) {
                const { modal: modalName } = val;

                if (modalName) {
                    this.$openModal(modalName),
                        this.$router.replace({ query: {} }).catch(() => {});
                }
            },
            immediate: true
        },

        selectedCampus: {
            handler(val) {
                if (val) {
                    const campus = this.campuses.find(
                        campus => campus.id === val.id
                    );

                    if (!campus) {
                        this.$cookies.remove('selectedCampus');
                        this.$openModal('campusChooser');
                    }

                    this.setSelectedCampus(campus || null);
                }
            },
            immediate: true
        }
    },

    mounted() {
        if (!this.selectedCampus) {
            const savedCampus = JSON.parse(
                this.$cookies.get('selectedCampus') || null
            );

            savedCampus
                ? this.setSelectedCampus(savedCampus)
                : this.$openModal('campusChooser');
        }
    },

    methods: {
        ...mapMutations({
            setSelectedCampus: 'campuses/SET_SELECTED_CAMPUS'
        })
    }
};
</script>
