<template>
    <div class="b_mini_banner">
        <div class="container">
            <div class="custom_a_banner">
                <a :href="ad.link" target="_blank" class="banner_link">
                    <picture v-full-image-path="ad.image" @click="onClick" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import BaseAd from '@/components/ads/Base';

export default {
    extends: BaseAd
};
</script>
