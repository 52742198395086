<template>
    <div class="footer_right_column">
        <div class="column_title second_font">
            אגודת הסטודנטים והסטודנטיות בעברית
        </div>
        <div class="drop_menu_wrapper">
            <button
                id="openDropdownButton"
                class="btn_menu"
                type="button"
                aria-label="Open campuses dropdown"
                @click="isDropdownOpened = true"
            >
                {{ selectedCampus ? selectedCampus.name : '' }}
            </button>
            <div
                v-click-outside="hideDropdown"
                class="drop_menu"
                :class="{ 'is-open': isDropdownOpened }"
            >
                <ul>
                    <li v-for="campus in campuses" :key="campus.id">
                        <button @click="onSelect(campus)">
                            {{ campus.name }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <client-only>
            <div v-if="selectedCampus" class="contacts_wrap">
                <div class="location">
                    {{ selectedCampus.address }}
                </div>
                <div class="contacts">
                    <div class="contact_row" dir="auto">
                        {{ selectedCampus.phone }}
                    </div>
                    <div class="contact_row">
                        {{ selectedCampus.email }}
                    </div>
                </div>
            </div>
        </client-only>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            isDropdownOpened: false
        };
    },

    computed: {
        ...mapGetters({
            loggedUser: 'loggedUser',
            campuses: 'campuses/campuses',
            selectedCampus: 'campuses/selectedCampus'
        })
    },

    methods: {
        ...mapMutations({
            setSelectedCampus: 'campuses/SET_SELECTED_CAMPUS'
        }),

        hideDropdown({ target: { id } }) {
            if (id === 'openDropdownButton') {
                return;
            }

            this.isDropdownOpened = false;
        },

        onSelect(campus) {
            this.setSelectedCampus(campus);

            this.$gtm.push({
                event: 'campus',
                campusId: this.selectedCampus.id,
                userId: this.loggedUser?.id || null
            });

            this.isDropdownOpened = false;
        }
    }
};
</script>
