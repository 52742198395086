export const defaultState = () => ({
    campuses: [],
    selectedCampus: null
});

export const state = () => defaultState();

export const getters = {
    campuses: state => state.campuses,
    selectedCampus: state => state.selectedCampus,
    defaultCampus: state =>
        state.campuses.find(campus => campus.isDefault === true)
};

export const mutations = {
    SET_CAMPUSES(state, campuses) {
        state.campuses = campuses;
    },
    SET_SELECTED_CAMPUS(state, campus) {
        state.selectedCampus = campus;

        if (campus) {
            this.$cookies.set('selectedCampus', JSON.stringify(campus));
        }
    }
};

export const actions = {
    async index({ commit }) {
        const campuses = await this.$axios.$get('/campuses');

        commit('SET_CAMPUSES', campuses);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
