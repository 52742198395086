'use strict';

export const defaultState = () => ({
    PENDING: {
        value: 'pending',
        text: 'ממתין לתגובה'
    },
    ACTIVE: {
        value: 'active',
        text: 'פעיל'
    },
    REJECTED: {
        value: 'rejected',
        text: 'נדחתה'
    },
    CANCELLED: {
        value: 'cancelled',
        text: 'בוטל'
    },
    ENDED: {
        value: 'ended',
        text: 'הסתיימה'
    }
});

export const state = () => defaultState();

export const getters = {
    ALL_STATUSES: state => [
        state.PENDING,
        state.ACTIVE,
        state.REJECTED,
        state.CANCELLED,
        state.ENDED
    ]
};

export const mutations = {};

export const actions = {
    index(ctx, { page = 1, perPage = 10 }) {
        const params = {
            page,
            perPage
        };

        return this.$axios.$get('/loans', {
            params
        });
    },

    show(ctx, id) {
        return this.$axios.$get(`/loans/${id}`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
