'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index() {
        return this.$axios.$get('/loan-shops');
    },

    show(ctx, slug) {
        return this.$axios.$get(`/loan-shops/${slug}`);
    },

    getTags(ctx, shopId) {
        return this.$axios.$get(`/loan-shops/${shopId}/tags`, {
            params: { all: true }
        });
    },

    getProducts(
        ctx,
        { id, page = 1, perPage = 10, filters = null, attributes = null }
    ) {
        const params = {
            page,
            perPage
        };

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        if (attributes) {
            params.attributes = JSON.stringify(attributes);
        }

        return this.$axios.$get(`/loan-shops/${id}/products`, {
            params
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
