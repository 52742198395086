'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    show(ctx, slug) {
        return this.$axios.$get(`/loan-products/${slug}`);
    },

    loan(ctx, { id, signature, disclaimer, _token }) {
        return this.$axios.$post(`/loan-products/${id}/loan`, {
            _token,
            signature,
            disclaimer
        });
    },

    remind(ctx, id) {
        return this.$axios.$post(`/loan-products/${id}/remind`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
