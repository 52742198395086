<template>
    <div :key="isMenuOpened" class="menu_body_inner flex jsTabs">
        <div class="main_menus_wrapper">
            <div class="main_menu_row jsTabsItem -active -fade">
                <div v-for="(section, index) in sections" :key="index">
                    <div
                        class="menu_row_title second_font"
                        :class="{
                            active: isMenuActive(index)
                        }"
                        @click="toggleMenu(index)"
                    >
                        {{ section.title }}
                    </div>
                    <div
                        class="mobile_wrap_main_menu"
                        :class="[
                            {
                                'is-active': isMenuActive(index)
                            },
                            `menu_list_${index}`
                        ]"
                    >
                        <div class="menus_wrapper_inner flex">
                            <items :selected-menu="menuItems[index]" />
                            <articles :articles="menuItems[index].articles" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Items from './menu-items/Items';
import Articles from './menu-items/Articles';

export default {
    components: {
        Items,
        Articles
    },

    props: {
        menuItems: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            openMenus: []
        };
    },

    computed: {
        ...mapGetters({
            isMenuOpened: 'isMenuOpened'
        }),

        selectedMenu() {
            return this.menuItems[this.selectedSectionIndex];
        },

        sections() {
            return this.menuItems.map(({ title, order }) => ({
                title,
                order
            }));
        }
    },

    watch: {
        isMenuOpened() {
            if (!this.isMenuOpened) {
                this.openMenus = [];
            }
        }
    },

    methods: {
        ...mapActions({
            initJQuery: 'scripts/initJQuery'
        }),

        setSelectedSection(index) {
            this.selectedSectionIndex = index;
        },

        toggleMenu(index) {
            this.initJQuery();

            const $ = window.jQuery;

            if (this.isMenuActive(index)) {
                this.openMenus = this.openMenus.filter(
                    menuIndex => menuIndex !== index
                );

                $(`.menu_list_${index}`).slideUp(200);

                return;
            }

            $(`.menu_list_${index}`).slideToggle(200);

            this.openMenus.push(index);
        },

        isMenuActive(index) {
            return this.openMenus.includes(index);
        }
    }
};
</script>
