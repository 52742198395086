export const defaultState = () => ({
    items: {}
});

export const state = () => defaultState();

export const getters = {
    all: state => state.items,
    getByKey: state => key => state.items[key]
};

export const mutations = {
    ADD_SINGLE_SETTING(state, { key, value }) {
        state.items[key] = value;
    }
};

export const actions = {
    async show({ getters, commit }, key) {
        let value = getters.getByKey(key);

        if (!value) {
            value = await this.$axios.$get(`/settings/${key}`);

            commit('ADD_SINGLE_SETTING', { key, value });
        }

        return value;
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
