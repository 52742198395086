<template>
    <div v-if="$showModal('join')" class="custom_popup_wrapper">
        <div class="custom_popup_inner">
            <div
                :id="modalId"
                v-click-outside="close"
                class="b_modal xs_size mfp-hide zoom-anim-dialog"
                :class="{ hero_image: !loggedSuccessfully }"
            >
                <div v-if="!loggedSuccessfully" class="modal_content_inner">
                    <tz-form
                        v-if="!otpSent && errorMessage"
                        @go-back="displayPhoneForm"
                        @open-sign-up-form="openSignUpForm"
                    />
                    <phone-form
                        v-else-if="!otpSent"
                        v-model="phone"
                        :error-message="errorMessage"
                        :is-processing="isProcessing"
                        is-join-modal
                        @send-otp="sendOtp"
                        @open-sign-up-form="openSignUpForm"
                    />
                    <otp-form
                        v-else
                        v-model="otp"
                        :error-message="errorMessage"
                        :is-processing="isProcessing"
                        @send-otp="sendOtp"
                        @login="handleLogin"
                    />

                    <recaptcha-message />
                </div>

                <logged-successfully v-else @submit="submit" @close="close" />

                <button
                    title="Close (Esc)"
                    type="button"
                    class="mfp-close"
                    @click="close"
                >
                    ×
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LoginModal from '@/components/modals/Login';

export default {
    extends: LoginModal
};
</script>
