<template>
    <div class="banners_wrapper">
        <div class="category_title second_font">
            כתבות מומלצות
        </div>
        <div
            v-for="article in articles"
            :key="article.id"
            class="banner_row flex"
        >
            <nuxt-link :to="`/articles/${article.slug}`" class="r_article flex">
                <div class="bottom_section">
                    <div class="image_thumbnail">
                        <div
                            v-full-background-image-path="article.featuredImage"
                            class="image bg_image cover"
                        />
                    </div>
                </div>
                <div class="top_section">
                    <div class="top_section_inner">
                        <div class="course_name">
                            {{ article.name }}
                        </div>
                        <div class="article_categories">
                            <div class="tags_list flex">
                                <div
                                    v-for="tag in article.tags"
                                    :key="tag.id"
                                    class="tag"
                                >
                                    <span>{{ tag.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nuxt-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        articles: {
            type: Array,
            required: true
        }
    }
};
</script>
