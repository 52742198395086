<template>
    <div class="footer_left_column">
        <div class="column_title second_font">
            עקבו אחרינו
        </div>
        <div class="social_list_wrap">
            <div class="social_list">
                <ul>
                    <li
                        v-for="(socialLink, index) of socialLinks"
                        :key="socialLink.text + index"
                    >
                        <a
                            :href="socialLink.url"
                            target="_blank"
                            class="social_link"
                            :aria-label="`${socialLink.text} profile hyperlink`"
                        >
                            <picture
                                :key="updateKey"
                                v-full-image-path="socialLink.icon"
                            />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            updateKey: 0
        };
    },

    computed: {
        ...mapGetters({
            selectedCampus: 'campuses/selectedCampus'
        }),

        socialLinks() {
            return this.selectedCampus?.socialMediaUrls || [];
        }
    },

    watch: {
        socialLinks() {
            this.updateKey++;
        }
    }
};
</script>
