<template>
    <div class="b_mega_menu" :class="{ 'is-open': isMenuOpened }">
        <div class="mega_menu_wrap">
            <div class="mega_menu_head">
                <div class="container">
                    <div class="head_wrap flex">
                        <div class="btn_wrap">
                            <button
                                class="btn_close_mm"
                                type="button"
                                @click="setIsMenuOpened(false)"
                            />
                        </div>
                        <div class="title_wrap">
                            <div class="title second_font">
                                בחר/י קטגוריה
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mega_menu_body">
                <div class="container">
                    <menu-mobile v-if="isMobile" :menu-items="menuItems" />
                    <menu-desktop v-else :menu-items="menuItems" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import MenuMobile from './MenuMobile';
import MenuDesktop from './MenuDesktop';

export default {
    components: {
        MenuMobile,
        MenuDesktop
    },

    props: {
        menuItems: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            isMobile: false
        };
    },

    computed: {
        ...mapGetters({
            isMenuOpened: 'isMenuOpened'
        })
    },

    watch: {
        '$route.path'() {
            this.setIsMenuOpened(false);
        },

        isMenuOpened() {
            this.initJQuery();

            const $ = window.jQuery;

            $('.b_mega_menu')[this.isMenuOpened ? 'fadeToggle' : 'fadeOut'](
                200
            );
        }
    },

    mounted() {
        this.isMobile = window.innerWidth < 992;

        window.addEventListener('resize', () => {
            this.isMobile = window.innerWidth < 992;
        });
    },

    methods: {
        ...mapMutations({
            setIsMenuOpened: 'SET_IS_MENU_OPENED'
        }),
        ...mapActions({
            initJQuery: 'scripts/initJQuery'
        })
    }
};
</script>
