'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(
        ctx,
        {
            page = 1,
            perPage = 10,
            search = '',
            sortBy = 'meetingDate',
            descending = true,
            type = null,
            year = null
        }
    ) {
        const params = {
            page,
            perPage,
            q: search,
            sortBy,
            order: descending ? 'DESC' : 'ASC'
        };

        if (type) {
            params.type = type;
        }

        if (year) {
            params.year = year;
        }

        return this.$axios.$get(`/transparency`, {
            params
        });
    },

    getYears() {
        return this.$axios.$get(`/transparency/years`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
