const getProductVariantPrice = require('./getProductVariantPrice');

class OrderValuesCalculator {
    #productVariants;
    #isPremiumUser;
    #totalPriceProducts;
    #total;

    constructor(productVariants, isPremiumUser) {
        this.#productVariants = productVariants;
        this.#isPremiumUser = isPremiumUser;

        this.calculateTotalPrice();

        this.#total = this.#totalPriceProducts;
    }

    get totalPriceProducts() {
        return this.#totalPriceProducts;
    }

    get total() {
        return this.#total;
    }

    calculateTotalPrice() {
        this.#totalPriceProducts = this.#productVariants.reduce(
            (total, item) =>
                total +
                getProductVariantPrice(item, this.#isPremiumUser) *
                    item.quantity,
            0
        );
    }
}

module.exports = OrderValuesCalculator;
