import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _54fcd682 = () => interopDefault(import('../../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _13d011f7 = () => interopDefault(import('../../pages/academic-bank/index.vue' /* webpackChunkName: "pages/academic-bank/index" */))
const _5faf033a = () => interopDefault(import('../../pages/academic-bank/index/index.vue' /* webpackChunkName: "pages/academic-bank/index/index" */))
const _cc2331e4 = () => interopDefault(import('../../pages/academic-bank/index/courses/_id/index.vue' /* webpackChunkName: "pages/academic-bank/index/courses/_id/index" */))
const _9dd2ea84 = () => interopDefault(import('../../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _27c1044e = () => interopDefault(import('../../pages/boards/index.vue' /* webpackChunkName: "pages/boards/index" */))
const _a944e2f2 = () => interopDefault(import('../../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _5585636e = () => interopDefault(import('../../pages/checkout/index/order/index.vue' /* webpackChunkName: "pages/checkout/index/order/index" */))
const _9e355a00 = () => interopDefault(import('../../pages/checkout/index/event/_id/index.vue' /* webpackChunkName: "pages/checkout/index/event/_id/index" */))
const _88e7f588 = () => interopDefault(import('../../pages/checkout/index/marathon/_id/index.vue' /* webpackChunkName: "pages/checkout/index/marathon/_id/index" */))
const _ec082fcc = () => interopDefault(import('../../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _f0285f3e = () => interopDefault(import('../../pages/loan-shops/index.vue' /* webpackChunkName: "pages/loan-shops/index" */))
const _0116d892 = () => interopDefault(import('../../pages/marathons/index.vue' /* webpackChunkName: "pages/marathons/index" */))
const _59e7fde4 = () => interopDefault(import('../../pages/personal-zone/index.vue' /* webpackChunkName: "pages/personal-zone/index" */))
const _4a6ba8af = () => interopDefault(import('../../pages/personal-zone/index/account/index.vue' /* webpackChunkName: "pages/personal-zone/index/account/index" */))
const _016dd339 = () => interopDefault(import('../../pages/personal-zone/index/orders/index.vue' /* webpackChunkName: "pages/personal-zone/index/orders/index" */))
const _325945e9 = () => interopDefault(import('../../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _36401b32 = () => interopDefault(import('../../pages/shops/index.vue' /* webpackChunkName: "pages/shops/index" */))
const _5bd93c98 = () => interopDefault(import('../../pages/terms-of-use/index.vue' /* webpackChunkName: "pages/terms-of-use/index" */))
const _65a251f1 = () => interopDefault(import('../../pages/thank-you/index.vue' /* webpackChunkName: "pages/thank-you/index" */))
const _2d9a026a = () => interopDefault(import('../../pages/thank-you/index/event/_id/index.vue' /* webpackChunkName: "pages/thank-you/index/event/_id/index" */))
const _3bd5b4dc = () => interopDefault(import('../../pages/thank-you/index/marathon/_id/index.vue' /* webpackChunkName: "pages/thank-you/index/marathon/_id/index" */))
const _3178cc1e = () => interopDefault(import('../../pages/thank-you/index/order/_id/index.vue' /* webpackChunkName: "pages/thank-you/index/order/_id/index" */))
const _71ff5b19 = () => interopDefault(import('../../pages/transparency/index.vue' /* webpackChunkName: "pages/transparency/index" */))
const _cb78a11a = () => interopDefault(import('../../pages/Payment/Failure.vue' /* webpackChunkName: "pages/Payment/Failure" */))
const _59a24d28 = () => interopDefault(import('../../pages/Payment/Success.vue' /* webpackChunkName: "pages/Payment/Success" */))
const _5f67d604 = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6df8ff79 = () => interopDefault(import('../../pages/articles/_slug/index.vue' /* webpackChunkName: "pages/articles/_slug/index" */))
const _260a6306 = () => interopDefault(import('../../pages/boards/_slug.vue' /* webpackChunkName: "pages/boards/_slug" */))
const _520f6605 = () => interopDefault(import('../../pages/events/_id/index.vue' /* webpackChunkName: "pages/events/_id/index" */))
const _67b58fe8 = () => interopDefault(import('../../pages/forms/_id.vue' /* webpackChunkName: "pages/forms/_id" */))
const _1a00ec4e = () => interopDefault(import('../../pages/loan-products/_slug.vue' /* webpackChunkName: "pages/loan-products/_slug" */))
const _f395a1ce = () => interopDefault(import('../../pages/loan-shops/_slug.vue' /* webpackChunkName: "pages/loan-shops/_slug" */))
const _2e654b06 = () => interopDefault(import('../../pages/marathons/_id/index.vue' /* webpackChunkName: "pages/marathons/_id/index" */))
const _05a13846 = () => interopDefault(import('../../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _0af10d37 = () => interopDefault(import('../../pages/reset-phone/_token.vue' /* webpackChunkName: "pages/reset-phone/_token" */))
const _348979ea = () => interopDefault(import('../../pages/shops/_slug.vue' /* webpackChunkName: "pages/shops/_slug" */))
const _5bfd917e = () => interopDefault(import('../../pages/marathons/_id/invitation.vue' /* webpackChunkName: "pages/marathons/_id/invitation" */))
const _0b95fc17 = () => interopDefault(import('../../pages/posts/_id/delete/_code.vue' /* webpackChunkName: "pages/posts/_id/delete/_code" */))
const _4a21be27 = () => interopDefault(import('../../pages/qr-codes/_userId/_eventId.vue' /* webpackChunkName: "pages/qr-codes/_userId/_eventId" */))
const _5db134bc = () => interopDefault(import('../../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _54fcd682,
    name: "about"
  }, {
    path: "/academic-bank",
    component: _13d011f7,
    children: [{
      path: "",
      component: _5faf033a,
      name: "academic-bank-index"
    }, {
      path: "courses/:id",
      component: _cc2331e4,
      name: "academic-bank-index-courses-id"
    }]
  }, {
    path: "/articles",
    component: _9dd2ea84,
    name: "articles"
  }, {
    path: "/boards",
    component: _27c1044e,
    name: "boards"
  }, {
    path: "/checkout",
    component: _a944e2f2,
    name: "checkout",
    children: [{
      path: "order",
      component: _5585636e,
      name: "checkout-index-order"
    }, {
      path: "event/:id",
      component: _9e355a00,
      name: "checkout-index-event-id"
    }, {
      path: "marathon/:id",
      component: _88e7f588,
      name: "checkout-index-marathon-id"
    }]
  }, {
    path: "/events",
    component: _ec082fcc,
    name: "events"
  }, {
    path: "/loan-shops",
    component: _f0285f3e,
    name: "loan-shops"
  }, {
    path: "/marathons",
    component: _0116d892,
    name: "marathons"
  }, {
    path: "/personal-zone",
    component: _59e7fde4,
    name: "personal-zone",
    children: [{
      path: "account",
      component: _4a6ba8af,
      name: "personal-zone-index-account"
    }, {
      path: "orders",
      component: _016dd339,
      name: "personal-zone-index-orders"
    }]
  }, {
    path: "/search",
    component: _325945e9,
    name: "search"
  }, {
    path: "/shops",
    component: _36401b32,
    name: "shops"
  }, {
    path: "/terms-of-use",
    component: _5bd93c98,
    name: "terms-of-use"
  }, {
    path: "/thank-you",
    component: _65a251f1,
    name: "thank-you",
    children: [{
      path: "event/:id",
      component: _2d9a026a,
      name: "thank-you-index-event-id"
    }, {
      path: "marathon/:id",
      component: _3bd5b4dc,
      name: "thank-you-index-marathon-id"
    }, {
      path: "order/:id",
      component: _3178cc1e,
      name: "thank-you-index-order-id"
    }]
  }, {
    path: "/transparency",
    component: _71ff5b19,
    name: "transparency"
  }, {
    path: "/Payment/Failure",
    component: _cb78a11a,
    name: "Payment-Failure"
  }, {
    path: "/Payment/Success",
    component: _59a24d28,
    name: "Payment-Success"
  }, {
    path: "/",
    component: _5f67d604,
    name: "index"
  }, {
    path: "/articles/:slug",
    component: _6df8ff79,
    name: "articles-slug"
  }, {
    path: "/boards/:slug",
    component: _260a6306,
    name: "boards-slug"
  }, {
    path: "/events/:id",
    component: _520f6605,
    name: "events-id"
  }, {
    path: "/forms/:id?",
    component: _67b58fe8,
    name: "forms-id"
  }, {
    path: "/loan-products/:slug?",
    component: _1a00ec4e,
    name: "loan-products-slug"
  }, {
    path: "/loan-shops/:slug?",
    component: _f395a1ce,
    name: "loan-shops-slug"
  }, {
    path: "/marathons/:id",
    component: _2e654b06,
    name: "marathons-id"
  }, {
    path: "/products/:slug?",
    component: _05a13846,
    name: "products-slug"
  }, {
    path: "/reset-phone/:token?",
    component: _0af10d37,
    name: "reset-phone-token"
  }, {
    path: "/shops/:slug",
    component: _348979ea,
    name: "shops-slug"
  }, {
    path: "/marathons/:id/invitation",
    component: _5bfd917e,
    name: "marathons-id-invitation"
  }, {
    path: "/posts/:id?/delete/:code?",
    component: _0b95fc17,
    name: "posts-id-delete-code"
  }, {
    path: "/qr-codes/:userId?/:eventId?",
    component: _4a21be27,
    name: "qr-codes-userId-eventId"
  }, {
    path: "/:slug",
    component: _5db134bc,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
