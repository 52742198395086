<template>
    <div v-if="!isClosed" class="b_mobile_ad_banner">
        <div class="ad_image_wrapper" :class="{ opened: isOpened }">
            <div class="image_wrapper">
                <button class="btn_close_ad" @click="onClose" />

                <div class="image_block">
                    <button class="btn_open_ad" @click="onOpen" />
                    <a :href="ad.link" target="_blank" class="image_link">
                        <picture
                            :key="isOpened ? 'opened' : 'not'"
                            v-full-image-path="isOpened ? image : bubbleBanner"
                            @click="onClick"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseAd from '@/components/ads/Base';

export default {
    extends: BaseAd,

    data() {
        return {
            isOpened: false,
            isClosed: false
        };
    },

    computed: {
        image() {
            return {
                ...this.ad.image,
                meta: {
                    ...this.ad.image.meta,
                    breakpoints: []
                }
            };
        },

        bubbleBanner() {
            return {
                ...this.ad.bubbleBanner,
                meta: {
                    ...this.ad.bubbleBanner.meta,
                    breakpoints: []
                }
            };
        }
    },

    methods: {
        async onOpen() {
            this.isOpened = true;
        },

        onClose() {
            this.isClosed = true;
        }
    }
};
</script>
