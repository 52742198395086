import Vue from 'vue';
import { mediaUrl } from '@/config';

const nonResponsiveExtensions = ['gif', 'svg'];

const FullImagePath = {
    bind(el, binding) {
        const { value, arg, modifiers } = binding;

        if (!value) {
            return null;
        }

        function loadAssetsImage() {
            let src = value;

            const newImage = document.createElement('img');

            if (modifiers['load-webp'] && checkIfWebSupported()) {
                const [name] = src.split('.');

                src = `${name}.webp`;
            }

            newImage.src = require(`@/assets/images/${src}`);
            newImage.alt = value.split('.').slice(0, -1)[0];

            el.appendChild(newImage);

            onImageLoad(newImage);

            window.addEventListener('resize', () => {
                onImageResize(el.lastChild);
            });
        }

        function handleIntersectForApi(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    createSourceSet(value, el, modifiers);
                    observer.unobserve(el);
                }
            });
        }

        function handleIntersectForAssets(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadAssetsImage();
                    observer.unobserve(el);
                }
            });
        }

        function createObserver(handler) {
            const options = {
                root: null,
                rootMargin: '100px',
                threshold: '0'
            };
            const observer = new IntersectionObserver(handler, options);
            observer.observe(el);
        }

        if (arg === 'assets') {
            if (window.IntersectionObserver && !modifiers['no-lazy']) {
                createObserver(handleIntersectForAssets);

                return;
            }

            loadAssetsImage();

            return;
        }

        if (window.IntersectionObserver) {
            createObserver(handleIntersectForApi);
        } else {
            createSourceSet(value, el, modifiers);
        }
    }
};

Vue.directive('full-image-path', FullImagePath);

function createSourceSet(image, element, modifiers) {
    const { id, meta, filename, date, extension, alt } = image;

    if (!id || !meta) {
        const newImage = document.createElement('img');

        newImage.src = require('@/assets/images/no-image.png');
        newImage.alt = 'no image';

        onImageLoad(newImage);

        element.appendChild(newImage);

        window.addEventListener('resize', () => {
            onImageResize(element.lastChild);
        });

        return null;
    }

    const isWebSupported = checkIfWebSupported(extension);

    if (modifiers['original']) {
        const newImage = document.createElement('img');

        newImage.src = `${mediaUrl}/images/${date}/${filename}.${
            isWebSupported ? 'webp' : extension
        }`;
        newImage.alt = filename;

        onImageLoad(newImage);

        element.appendChild(newImage);

        window.addEventListener('resize', () => {
            onImageResize(element.lastChild);
        });

        return null;
    }

    if (!nonResponsiveExtensions.includes(extension)) {
        const breakpoints = [...meta.breakpoints];
        const { width: imageWidth } = meta;
        const basePath = `${mediaUrl}/images/${date}/${filename}`;

        buildBreakpoints(
            breakpoints,
            imageWidth,
            element,
            isWebSupported,
            basePath,
            extension
        );

        let initWindowWidth = window.innerWidth;

        window.addEventListener('resize', async () => {
            const currentWindowWidth = window.innerWidth;

            onImageResize(element.lastChild);

            if (
                !modifiers['resizable'] &&
                currentWindowWidth <= initWindowWidth
            ) {
                return;
            }

            initWindowWidth = currentWindowWidth;

            while (element.hasChildNodes()) {
                element.removeChild(element.firstChild);
            }

            buildBreakpoints(
                breakpoints,
                imageWidth,
                element,
                isWebSupported,
                basePath,
                extension
            );

            const newImage = document.createElement('img');
            newImage.src = `${mediaUrl}/images/${date}/${filename}.${
                isWebSupported ? 'webp' : extension
            }`;
            newImage.alt = alt;

            element.appendChild(newImage);
        });
    }

    const newImage = document.createElement('img');
    newImage.src = `${mediaUrl}/images/${date}/${filename}.${
        isWebSupported ? 'webp' : extension
    }`;
    newImage.alt = alt;

    onImageLoad(newImage);

    element.appendChild(newImage);
}

function buildBreakpoints(
    initBreakpoints,
    imageWidth,
    element,
    isWebSupported,
    basePath,
    originalExtension
) {
    const breakpoints = [...initBreakpoints];

    if (imageWidth) {
        breakpoints.push(parseInt(imageWidth));
    }

    const { offsetWidth: elementWidth } = element.parentElement || element;

    breakpoints.sort((a, b) => a - b);

    const availableBreakpoints = breakpoints.filter(
        breakpoint => breakpoint <= elementWidth
    );

    if (!availableBreakpoints.length) {
        availableBreakpoints.push(breakpoints[0]);
    }

    const lastAvailableBreakpoint = [...availableBreakpoints].pop();

    if (
        lastAvailableBreakpoint < elementWidth &&
        lastAvailableBreakpoint !== imageWidth
    ) {
        const fillableBreakpoint = breakpoints.find(
            breakpoint => breakpoint > elementWidth
        );

        availableBreakpoints.push(fillableBreakpoint);
    }

    availableBreakpoints.forEach((breakpoint, index, array) => {
        const extension = isWebSupported ? 'webp' : originalExtension;
        const isLast = index === array.length - 1;
        const mediaType = isLast ? 'min-width' : 'max-width';
        const widthValue = isLast
            ? array[index - 1]
                ? array[index - 1] + 1
                : 0
            : breakpoint;

        const source = document.createElement('source');
        source.type = `image/${extension}`;

        source.srcset = `${basePath}${
            breakpoint === imageWidth ? '' : `_${breakpoint}`
        }.${extension}`;
        source.media = `(${mediaType}: ${widthValue}px)`;

        element.appendChild(source);
    });
}

function checkIfWebSupported(extension) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return !isSafari && !nonResponsiveExtensions.includes(extension);
}

function onImageLoad(image) {
    image.onload = function() {
        image.width = this.width;
        image.height = this.height;
    };
}

function onImageResize(image) {
    image.removeAttribute('width');
    image.removeAttribute('height');
}
