<template>
    <div class="login_control">
        <button
            v-if="!loggedUser"
            class="login_link"
            @click="$openModal('login')"
        >
            התחברות
        </button>
        <div v-else class="user_control">
            <div class="drop_menu_wrapper">
                <button
                    class="btn_user"
                    type="button"
                    @click="isProfileMenuOpened = true"
                >
                    <span class="user_icon unhiddable" />
                    <span class="user_title unhiddable">
                        <span class="label unhiddable">
                            שלום,
                        </span>
                        <span class="user_name unhiddable">
                            {{ loggedUser.firstName }}
                        </span>
                    </span>
                </button>
                <div
                    v-click-outside="hideMenu"
                    class="drop_menu jsDropMenu"
                    :class="{
                        'is-open': isProfileMenuOpened
                    }"
                >
                    <ul>
                        <li>
                            <nuxt-link to="/personal-zone/account">
                                איזור אישי
                            </nuxt-link>
                        </li>
                    </ul>
                    <button class="log_out" @click="logout">
                        התנתקות
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            isProfileMenuOpened: false
        };
    },

    computed: {
        ...mapGetters({
            loggedUser: 'loggedUser',
            selectedCampus: 'campuses/selectedCampus'
        })
    },

    watch: {
        '$route.path'() {
            this.isProfileMenuOpened = false;
        }
    },

    methods: {
        ...mapActions({
            clearCart: 'cart/clear'
        }),

        hideMenu({ target: { className } }) {
            if (className?.includes('unhiddable')) {
                return;
            }

            this.isProfileMenuOpened = false;
        },

        async logout() {
            try {
                await this.$auth.logout();

                this.clearCart();

                this.isProfileMenuOpened = false;
                this.$router.push('/');
                this.$toasted.success('התנתקת');

                this.$gtm.push({
                    event: 'campus',
                    campusId: this.selectedCampus.id,
                    userId: null
                });
            } catch (err) {
                console.error(err);

                this.$toasted.global.error();
            }
        }
    }
};
</script>
