export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(ctx, { page = 1, perPage = 12, search = '' }) {
        const params = {
            page,
            perPage
        };

        if (search) {
            params.q = search;
        }

        return this.$axios.$get('/about-us', { params });
    },

    async show(ctx, { page = 1, perPage = 12, search = '', id }) {
        const params = {
            page,
            perPage
        };

        if (search) {
            params.q = search;
        }

        return this.$axios.$get(`/about-us/${id}`, { params });
    },

    contactUs(ctx, data) {
        return this.$axios.$post('/about-us/contact-us', data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
