import { loadFonts, fontsToLinks } from './utils/fontLoader'

import './font-face.css';

const fonts = [{"preload":true,"source":{"path":require('@/assets/design/fonts/37-Regular.woff2'),"format":"woff2"},"classes":["font_37","font_37_normal_normal"]}];

if (!process.server){
  loadFonts({"ignoredEffectiveTypes":["2g","slow-2g"],"ignoreLighthouse":true,"unlockDelay":0,"prefetchCount":2});
}

export default function ({ app }) {
  app.head.link.push(...fontsToLinks(fonts));
}
