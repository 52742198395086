'use strict';

export const defaultState = () => ({
    OFFENSIVE: {
        value: 'offensive',
        text: 'תגובה פוגענית'
    },
    UNRELATED: {
        value: 'unrelated',
        text: 'תגובה לא קשורה לנושא'
    },
    OTHER: {
        value: 'other',
        text: 'אחר'
    }
});

export const state = () => defaultState();

export const getters = {
    REPORT_TYPES: state => [state.OFFENSIVE, state.UNRELATED, state.OTHER]
};

export const mutations = {};

export const actions = {
    store(ctx, { courseId, commentData = '', fileId = null, quotedId = null }) {
        return this.$axios.$post('/comments', {
            academicCourseId: courseId,
            details: commentData,
            fileId,
            quotedId
        });
    },

    report(ctx, { id, ...data }) {
        return this.$axios.$post(`comments/${id}/report`, data);
    },

    vote(ctx, { id, isUpvoted }) {
        return this.$axios.$post(`comments/${id}/vote`, { isUpvoted });
    },

    delete(ctx, id) {
        return this.$axios.$delete(`comments/${id}`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
