export const defaultState = () => ({ isFancyboxInitialized: false });

export const state = () => defaultState();

export const getters = {
    isFancyboxInitialized: state => state.isFancyboxInitialized
};

export const mutations = {
    SET_IS_FANCYBOX_INITIALIZED(state, val) {
        state.isFancyboxInitialized = val;
    }
};

export const actions = {
    initJQuery() {
        if (window.jQuery) {
            return;
        }

        const $ = require('jquery');

        window.jQuery = $;
    },

    initFancybox({ dispatch, getters, commit }) {
        if (getters.isFancyboxInitialized) {
            return;
        }

        dispatch('initJQuery');

        require('@fancyapps/fancybox');
        commit('SET_IS_FANCYBOX_INITIALIZED', true);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
