<script>
import { mapActions } from 'vuex';

export default {
    props: {
        ad: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            timeout: null
        };
    },

    mounted() {
        this.timeout = setTimeout(() => {
            if (this.ad) {
                this.addView(this.ad.id);
            }
        }, 1000);
    },

    beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    },

    methods: {
        ...mapActions({
            addView: 'ads/addView',
            addClick: 'ads/addClick'
        }),

        async onClick() {
            await this.addClick(this.ad.id);
        }
    }
};
</script>
