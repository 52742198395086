'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    show(ctx, id) {
        return this.$axios.$get(`/forms/${id}`);
    },

    sendSubmission(ctx, { id, submission }) {
        return this.$axios.$post(`/forms/${id}/submissions`, submission);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
