<template>
    <div v-if="$showModal('sign-up')" class="custom_popup_wrapper">
        <div class="custom_popup_inner">
            <div
                id="register"
                v-click-outside="close"
                class="b_modal register_form xs_size mfp-hide zoom-anim-dialog "
            >
                <div class="modal_content_inner">
                    <div
                        class="modal_title second_font text_center title_decor"
                    >
                        <span>הרשמה</span>
                    </div>

                    <form @submit.prevent="submit">
                        <div class="rows_wrapper">
                            <div class="form_row">
                                <div class="form_label">
                                    שם פרטי
                                </div>
                                <input
                                    v-model="formData.firstName"
                                    class="form_control"
                                    type="text"
                                    :class="{
                                        'is-invalid': hasError('firstName')
                                    }"
                                />
                                <div class="invalid-feedback">
                                    {{ getError('firstName') }}
                                </div>
                            </div>
                            <div class="form_row">
                                <div class="form_label">
                                    שם משפחה
                                </div>
                                <input
                                    v-model="formData.lastName"
                                    class="form_control"
                                    type="text"
                                    :class="{
                                        'is-invalid': hasError('lastName')
                                    }"
                                />
                                <div class="invalid-feedback">
                                    {{ getError('lastName') }}
                                </div>
                            </div>
                            <div class="form_row">
                                <div class="form_label">
                                    ת"ז
                                </div>
                                <input
                                    v-model="formData.tz"
                                    class="form_control hide-arrows"
                                    type="number"
                                    :class="{
                                        'is-invalid': hasError('tz')
                                    }"
                                />
                                <div class="invalid-feedback">
                                    {{ getError('tz') }}
                                </div>
                            </div>
                            <div class="form_row">
                                <div class="form_label">
                                    כתובת דוא״ל
                                </div>
                                <input
                                    v-model="formData.email"
                                    class="form_control"
                                    type="email"
                                    :class="{
                                        'is-invalid': hasError('email')
                                    }"
                                />
                                <div class="invalid-feedback">
                                    {{ getError('email') }}
                                </div>
                            </div>
                            <div class="form_row">
                                <div class="form_label">
                                    טלפון
                                </div>
                                <input
                                    v-model="formData.phone"
                                    class="form_control hide-arrows"
                                    type="number"
                                    :class="{
                                        'is-invalid': hasError('phone')
                                    }"
                                />
                                <div class="invalid-feedback">
                                    {{ getError('phone') }}
                                </div>
                            </div>

                            <div class="btn_wrapper">
                                <button class="btn btn_green">
                                    הרשמה
                                </button>
                            </div>
                        </div>
                    </form>

                    <div class="register_link">
                        <span>כבר יש לך חשבון?</span>
                        <a @click.prevent="openLoginForm">
                            להתחברות
                        </a>
                    </div>

                    <recaptcha-message />
                </div>

                <button
                    title="Close (Esc)"
                    type="button"
                    class="mfp-close"
                    @click="close"
                >
                    ×
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { StatusCodes as HTTP } from 'http-status-codes';
import recaptchaMixin from '@/mixins/recaptcha.mixin.js';
import { required, minLength, email } from 'vuelidate/lib/validators';

export default {
    validations: {
        formData: {
            firstName: {
                required,
                minLength: minLength(2)
            },
            lastName: {
                required,
                minLength: minLength(2)
            },
            tz: {
                required
            },
            email: {
                required,
                email
            },
            phone: {
                required
            }
        }
    },

    mixins: [recaptchaMixin],

    data() {
        const defaultForm = {
            firstName: '',
            lastName: '',
            tz: '',
            email: '',
            phone: ''
        };

        return {
            defaultForm,
            formData: { ...defaultForm },
            serverErrors: []
        };
    },

    methods: {
        ...mapActions({
            register: 'account/register'
        }),

        async submit() {
            this.serverErrors = [];

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                const token = await this.$recaptcha.execute('register');

                await this.register({
                    ...this.formData,
                    _token: token
                });

                this.$toasted.success('החשבון נוצר. נא להתחבר');

                this.close();
            } catch (error) {
                const { response } = error;

                if (response) {
                    const { data, status } = response;

                    if (status === HTTP.BAD_REQUEST && data.errors) {
                        this.serverErrors = data.errors;

                        this.$toasted.error('בדוק את הטופס!');

                        return;
                    }
                }

                console.error(error);
            }
        },

        close() {
            this.formData = { ...this.defaultForm };
            this.$v.formData.$reset();
            this.serverErrors = [];

            this.$closeModal();
        },

        hasError(key) {
            return (
                this.$v.formData[key].$error ||
                this.serverErrors.find(error => error.param === key)
            );
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.$v.formData,
                this.serverErrors,
                key
            );
        },

        openLoginForm() {
            this.close();

            this.$openModal('login');
        }
    }
};
</script>
