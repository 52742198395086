export const defaultState = () => ({
    posts: [],
    selectedPost: null
});

export const state = () => defaultState();

export const getters = {
    posts: state => state.posts,
    getPostById: state => id => state.posts.find(item => item.id === id)
};

export const mutations = {};

export const actions = {
    async index(
        ctx,
        {
            boardId = null,
            page = 1,
            perPage = 8,
            search = '',
            sortBy = 'createdAt',
            descending = true
        }
    ) {
        const params = {
            boardId,
            page,
            perPage,
            q: search,
            sortBy,
            order: descending ? 'DESC' : 'ASC'
        };

        const posts = await this.$axios.$get('/posts', { params });

        return posts;
    },

    async show({ getters }, id) {
        let post = getters.getPostById(id);

        if (!post) {
            post = await this.$axios.$get(`/posts/${id}`);
        }

        return post;
    },

    store(ctx, postData) {
        return this.$axios.$post('posts', postData);
    },

    delete(ctx, { id, code }) {
        return this.$axios.$delete(`posts/${id}/${code}`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
