import OrderValuesCalculator from '../../common/orders/OrderValuesCalculator';

export const defaultState = () => ({
    items: [],
    currentShopId: null,
    canPayWithStars: false
});

export const state = () => defaultState();

export const getters = {
    items: state => state.items,
    currentShopId: state => state.currentShopId,
    canPayWithStars: state => state.canPayWithStars,
    totalQuantity: state =>
        state.items.reduce((total, item) => total + item.quantity, 0),
    values(state, getters, rootState, rootGetters) {
        const { items } = getters;
        const isPremiumUser = rootGetters.isPremiumUser;

        const values = new OrderValuesCalculator(items, isPremiumUser);

        return values;
    },
    total: (state, getters) => getters.values.total
};

export const mutations = {
    ADD_ITEM(state, item) {
        state.items.push(item);
    },

    INCREMENT_QUANTITY(state, { index, value }) {
        state.items[index].quantity += value;
    },

    SET_CURRENT_SHOP_ID(state, id) {
        state.currentShopId = id;
    },

    SET_CAN_PAY_WITH_STARS(state, canPayWithStars) {
        state.canPayWithStars = canPayWithStars;
    },

    DESTROY_ITEM(state, id) {
        const index = state.items.findIndex(item => item.id === id);

        if (~index) {
            state.items.splice(index, 1);
        }
    },

    CLEAR(state) {
        Object.assign(state, defaultState());
    }
};

export const actions = {
    addItem({ commit, getters: { items } }, item) {
        const index = items.findIndex(({ id }) => id === item.id);

        if (~index) {
            return commit('INCREMENT_QUANTITY', {
                index,
                value: item.quantity || 1
            });
        }

        commit('ADD_ITEM', item);
    },

    removeItem({ commit }, id) {
        commit('DESTROY_ITEM', id);
    },

    setCurrentShopId({ commit }, id) {
        commit('SET_CURRENT_SHOP_ID', id);
    },

    setCanPayWithStars({ commit }, canPayWithStars) {
        commit('SET_CAN_PAY_WITH_STARS', canPayWithStars);
    },

    clear({ commit }) {
        commit('CLEAR');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
