import { cloneDeep } from 'lodash';

export const defaultState = () => ({
    content: []
});

export const state = () => defaultState();

export const getters = {
    getContent: state => state.content,
    getContentByKey: state => key =>
        state.content.find(item => item.key === key)
};

export const mutations = {
    SET_CONTENT(state, content) {
        state.content = content;
    },
    ADD_SINGLE_CONTENT_SECTION(state, content) {
        state.content = [...state.content, content];
    }
};

export const actions = {
    async index({ commit }, type = 'component') {
        const content = await this.$axios.$get('/contents', {
            params: { type }
        });

        commit('SET_CONTENT', content);
    },

    async show({ getters, commit }, key) {
        let content = cloneDeep(getters.getContentByKey(key));

        if (!content) {
            content = await this.$axios.$get(`/contents/${key}`);

            commit('ADD_SINGLE_CONTENT_SECTION', content);
        }

        return content.type === 'page'
            ? { content: content.value, meta: content.meta }
            : content.value;
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
