var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.isMenuOpened,staticClass:"menu_body_inner flex jsTabs"},[_c('div',{staticClass:"main_menus_wrapper"},[_c('div',{staticClass:"main_menu_row jsTabsItem -active -fade"},_vm._l((_vm.sections),function(section,index){return _c('div',{key:index},[_c('div',{staticClass:"menu_row_title second_font",class:{
                        active: _vm.isMenuActive(index)
                    },on:{"click":function($event){return _vm.toggleMenu(index)}}},[_vm._v("\n                    "+_vm._s(section.title)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"mobile_wrap_main_menu",class:[
                        {
                            'is-active': _vm.isMenuActive(index)
                        },
                        `menu_list_${index}`
                    ]},[_c('div',{staticClass:"menus_wrapper_inner flex"},[_c('items',{attrs:{"selected-menu":_vm.menuItems[index]}}),_vm._v(" "),_c('articles',{attrs:{"articles":_vm.menuItems[index].articles}})],1)])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }