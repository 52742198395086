<template>
    <div v-if="$showModal('campusChooser')" class="custom_popup_wrapper">
        <div class="custom_popup_inner">
            <div class="b_modal xs_size hero_image mfp-hide zoom-anim-dialog">
                <div class="modal_content_inner">
                    <div
                        class="modal_title second_font text_center title_decor"
                    >
                        בחירת קמפוס
                    </div>
                    <div class="campuses_list">
                        <div
                            v-for="(campus, index) in campuses"
                            :key="index"
                            class="list_row"
                        >
                            <button class="btn_link" @click="onSelect(campus)">
                                {{ campus.name }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            loggedUser: 'loggedUser',
            campuses: 'campuses/campuses',
            selectedCampus: 'campuses/selectedCampus'
        })
    },

    methods: {
        ...mapMutations({
            setSelectedCampus: 'campuses/SET_SELECTED_CAMPUS'
        }),

        onSelect(campus) {
            this.setSelectedCampus(campus);

            this.$gtm.push({
                event: 'campus',
                campusId: this.selectedCampus.id,
                userId: this.loggedUser?.id || null
            });

            this.$closeModal();
        }
    }
};
</script>
