import Vue from 'vue';

const formatNumber = value => {
    if (!value) {
        return '';
    }

    value = value.toString();

    if (value.length <= 3) {
        return value;
    }

    const chunks = value.match(/\d{1,3}(?=(\d{3})*$)/g);

    const lastThreeDigits = chunks.pop();

    return `${chunks.join(' ')},${lastThreeDigits}`;
};

Vue.filter('formatNumber', formatNumber);
