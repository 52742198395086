<template>
    <div>
        <div class="header_section" :class="{ 'is-sticky': isSticky }">
            <div class="sticky-wrapper" :class="{ 'is-sticky': isSticky }">
                <header class="b_header">
                    <div class="header">
                        <div class="container">
                            <div class="header_wrapper flex">
                                <div class="right_column flex">
                                    <div class="btn_menu">
                                        <button
                                            class="menu_toggler jsMenu"
                                            aria-label="Toggle menu"
                                            @click="setIsMenuOpened(true)"
                                        >
                                            <span class="toggler_top" />
                                            <span class="toggler_middle" />
                                            <span class="toggler_bottom" />
                                        </button>
                                    </div>
                                    <div class="logo_wrapper">
                                        <nuxt-link
                                            to="/"
                                            class="logo_link_mobile"
                                        >
                                            <picture
                                                v-full-image-path:assets="
                                                    'logo.svg'
                                                "
                                            />
                                        </nuxt-link>
                                        <nuxt-link to="/" class="logo_link">
                                            <div class="logo_icon">
                                                <picture
                                                    v-full-image-path:assets="
                                                        'logo_icon.svg'
                                                    "
                                                />
                                            </div>
                                            <div class="logo_text">
                                                <picture
                                                    v-full-image-path:assets="
                                                        'logo_text.svg'
                                                    "
                                                />
                                            </div>
                                        </nuxt-link>
                                    </div>
                                </div>
                                <div class="left_column flex">
                                    <search />
                                    <client-only>
                                        <login-control />
                                    </client-only>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </div>

        <mega-menu :menu-items="content.menuItems" />
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import LoginControl from './header-items/LoginControl';
import MegaMenu from './header-items/Menu';
import Search from './header-items/Search';

export default {
    components: {
        LoginControl,
        MegaMenu,
        Search
    },

    data() {
        return {
            content: {
                menuItems: []
            },
            isSticky: false
        };
    },

    async fetch() {
        try {
            const content = await this.fetchContent('appHeader');

            await this.onCampusChange(content);
        } catch (error) {
            console.error(error);

            this.$toasted.global.error();
        }
    },

    computed: {
        ...mapGetters({
            selectedCampus: 'campuses/selectedCampus',
            defaultCampus: 'campuses/defaultCampus'
        })
    },

    watch: {
        async selectedCampus(val, oldVal) {
            if (oldVal?.id === val?.id) {
                return;
            }

            try {
                const content = await this.fetchContent('appHeader');

                await this.onCampusChange(content);
            } catch (error) {
                console.error(error);

                this.$toasted.global.error();
            }
        }
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll, { passive: true });
    },

    methods: {
        ...mapMutations({
            setIsMenuOpened: 'SET_IS_MENU_OPENED'
        }),

        ...mapActions({
            fetchContent: 'contents/show',
            getArticles: 'articles/bulkShow'
        }),

        onScroll() {
            const currentScrollPosition =
                window.pageYOffset || document.documentElement.scrollTop;

            this.isSticky = currentScrollPosition > 0;
        },

        async onCampusChange(content) {
            const campusId = this.selectedCampus
                ? this.selectedCampus.id
                : this.defaultCampus.id;

            if (content.menus) {
                const { menuItems } = content.menus.find(
                    menu => menu.campusId === campusId
                );

                this.content.menuItems = menuItems;
            }

            const ids = this.content.menuItems.flatMap(
                ({ articles }) => articles
            );

            try {
                const articles = await this.getArticles(ids);

                for (const [
                    menuItemIndex,
                    menuItem
                ] of this.content.menuItems.entries()) {
                    for (const [
                        articleIndex,
                        article
                    ] of menuItem.articles.entries()) {
                        this.content.menuItems[menuItemIndex].articles[
                            articleIndex
                        ] = articles.find(({ id }) => id === article);
                    }

                    this.content.menuItems[
                        menuItemIndex
                    ].articles = this.content.menuItems[
                        menuItemIndex
                    ].articles.filter(article => article && article.id);
                }
            } catch (error) {
                console.error(error);

                this.$toasted.global.error();
            }
        }
    }
};
</script>
