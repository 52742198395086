<template>
    <div class="columns_wrapper">
        <div class="columns_list flex">
            <div
                v-for="(column, columnIndex) of columns"
                :key="column.title + columnIndex"
                class="column"
            >
                <div class="column_title second_font">
                    {{ column.title }}
                </div>

                <div class="menu_wrapper">
                    <ul>
                        <li
                            v-for="(link, linkIndex) of column.links"
                            :key="link.text + linkIndex"
                        >
                            <a :href="link.link" target="_blank">
                                {{ link.text }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        columns: {
            type: Array,
            required: true
        }
    }
};
</script>
