module.exports = (productVariant, isPremiumUser) => {
    const {
        agudaPrice,
        price,
        product: { agudaPrice: productAgudaPrice, price: productPrice }
    } = productVariant;

    const productVariantPrice = isPremiumUser ? agudaPrice || price : price;

    const defaultPrice = isPremiumUser
        ? productAgudaPrice || productPrice
        : productPrice;

    return productVariantPrice || defaultPrice;
};
