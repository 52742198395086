export const defaultState = () => ({
    ROLE_AGUDA: 'aguda',
    ROLE_USER: 'user'
});

export const state = () => defaultState();

export const getters = {
    ROLE_AGUDA: state => state.ROLE_AGUDA,
    ROLE_USER: state => state.ROLE_USER
};

export const mutations = {};

export const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
};
