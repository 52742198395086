'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(
        ctx,
        {
            page = 1,
            perPage = 6,
            search = '',
            sortBy = 'updatedAt',
            descending = true,
            source = '',
            tags = []
        }
    ) {
        const params = {
            page,
            perPage,
            q: search,
            sortBy,
            source,
            order: descending ? 'DESC' : 'ASC'
        };

        if (tags.length) {
            const filters = {
                tags
            };

            params.filters = JSON.stringify(filters);
        }

        return this.$axios.$get('/articles', {
            params
        });
    },

    show(ctx, slug) {
        return this.$axios.$get(`/articles/${slug}`);
    },

    bulkShow(ctx, ids) {
        return this.$axios.$get('/articles/bulk-show', {
            params: { ids }
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
