import Vue from 'vue';

const truncate = value => {
    if (!value) {
        return '';
    }

    return value.length > 140 ? `${value.substring(0, 140)}...` : value;
};

Vue.filter('truncate', truncate);
