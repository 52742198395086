export const defaultState = () => {};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    show(vuexContext, slug) {
        return this.$axios.$get(`/pages/${slug}`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
