export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    remind(ctx, id) {
        return this.$axios.$post(`/product-variants/${id}/remind`);
    },

    show(ctx, id) {
        return this.$axios.$get(`/product-variants/${id}`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
