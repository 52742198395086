<template>
    <div class="courses_column">
        <div class="course_block_wrapper">
            <a
                :href="ad.link"
                target="_blank"
                class="course_block banner"
                @click="onClick"
            >
                <div class="course_block_inner flex">
                    <div
                        v-full-background-image-path="ad.image"
                        class="icon_wrapper top_section"
                    >
                        <div class="icon" />
                    </div>

                    <div class="course_info bottom_section">
                        <div class="course_title">
                            {{ ad.title }}
                        </div>

                        <div class="nubers_wrapper">
                            {{ ad.description }}
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import BaseAd from '@/components/ads/Base';

export default {
    extends: BaseAd
};
</script>
