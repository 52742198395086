'use strict';

export const defaultState = () => ({
    data: null,
    modal: null
});

export const state = () => defaultState();

export const getters = {
    data: state => state.data,
    blur: state => !!state.modal,
    show: state => modal => state.modal === modal
};

export const mutations = {
    OPEN(state, modal) {
        state.modal = modal;
    },

    CLOSE(state) {
        state.modal = null;
        state.data = null;
    },

    SET_DATA(state, data) {
        state.data = data;
    }
};

export const actions = {
    open({ commit }, { name, data }) {
        data && commit('SET_DATA', data);

        commit('OPEN', name);
    },

    close({ commit }) {
        commit('CLOSE');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
