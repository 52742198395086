<template>
    <div>
        <div v-if="isJoinModal" class="title_decor text_center mb-1">
            אוי לא! אין לך הרשאות לצפות בדף הזה
            <br />
            חבר אגודה? התחבר למערכת כדי לצפות בדף
        </div>
        <div class="modal_title second_font text_center title_decor">
            התחברות
        </div>
        <div class="form_wrapper">
            <form @submit.prevent="$emit('send-otp')">
                <div class="rows_wrapper">
                    <div class="form_row">
                        <label class="form_label">הזנ/י מספר טלפון</label>
                        <input
                            :value="value"
                            type="number"
                            class="form_control hide-arrows"
                            @input="$emit('input', $event.target.value)"
                        />
                    </div>
                </div>
                <div class="btn_wrapper">
                    <button
                        type="submit"
                        class="btn btn_green"
                        :disabled="isProcessing || !value"
                    >
                        התחבר/י
                    </button>
                </div>
            </form>
            <div v-if="isJoinModal">
                <span>
                    אינך חבר אגודה?
                </span>
                <a @click.prevent="$emit('open-sign-up-form')">
                    בוא תצטרף אלינו!
                </a>
            </div>
            <div v-else class="register_link">
                <span>אין לך חשבון?</span>
                <a @click.prevent="$emit('open-sign-up-form')">להרשמה</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        errorMessage: {
            type: String,
            required: true
        },
        isProcessing: {
            type: Boolean,
            required: true
        },
        isJoinModal: {
            type: Boolean,
            required: false
        }
    }
};
</script>
