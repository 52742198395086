<template>
    <div>
        <div class="modal_title second_font text_center title_decor">
            התחברות
        </div>
        <div class="form_wrapper">
            <form @submit.prevent="$emit('login')">
                <div class="rows_wrapper">
                    <div class="form_row">
                        <label class="form_label">הזנ/י קוד</label>
                        <input
                            :value="value"
                            type="number"
                            class="form_control hide-arrows"
                            @input="$emit('input', $event.target.value)"
                        />
                    </div>
                    <div v-if="errorMessage" class="info_messages">
                        קוד שגוי, אנא נסה/י שוב
                    </div>
                </div>
                <div class="btn_wrapper">
                    <button
                        type="submit"
                        class="btn btn_green"
                        :disabled="isProcessing || !value"
                    >
                        התחבר/י
                    </button>
                </div>
                <div class="no_code">
                    <span>לא קיבלת קוד? </span>
                    <a @click.prevent="$emit('send-otp', { resend: true })">
                        <span>לחצ/י כאן</span>
                    </a>
                    <span>או</span>
                    <a
                        @click.prevent="
                            $emit('send-otp-email', { email: true })
                        "
                    >
                        <span>שלח קוד אימות דרך האימייל</span>
                    </a>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        errorMessage: {
            type: String,
            required: true
        },
        isProcessing: {
            type: Boolean,
            required: true
        }
    }
};
</script>
