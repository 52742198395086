<template>
    <div>
        <div class="modal_title second_font text_center title_decor">
            התחברות
            <button class="btn_reset btn_back" @click="$emit('go-back')" />
        </div>
        <div v-if="!isSent" class="form_wrapper">
            <div class="unsuccessful_message">
                <div class="title">
                    אז ככה,<br />
                    לא הצלחנו לזהות את סטטוס החברות שלך באמצעות מספר הטלפון.
                </div>

                <div class="message">
                    אולי נצליח יותר עם מספר תעודת הזהות.
                </div>
            </div>

            <form @submit.prevent="resetPhone">
                <div class="rows_wrapper">
                    <div class="form_row">
                        <label class="form_label"> הזן תעודת זהות. </label>
                        <input
                            v-model="tz"
                            type="number"
                            class="form_control hide-arrows"
                        />
                    </div>
                </div>
                <div class="btn_wrapper">
                    <button
                        type="submit"
                        class="btn btn_green"
                        :disabled="isProcessing || !tz"
                    >
                        התחבר/י
                    </button>
                </div>

                <div class="register_link">
                    <span>אין לך חשבון?</span>
                    <a @click.prevent="$emit('open-sign-up-form')">להרשמה</a>
                </div>
            </form>
            <div v-if="errorMessage" class="error-box">
                {{ errorMessage }}
            </div>
        </div>
        <div v-else class="success-box">
            נשלח מייל לאיפוס המספר טלפון לצורך התחברות למערכת
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { StatusCodes } from 'http-status-codes';

export default {
    data() {
        return {
            isProcessing: false,
            tz: '',
            errorMessage: '',
            isSent: false
        };
    },

    methods: {
        ...mapActions({ resetPhoneLink: 'account/resetPhoneLink' }),

        async resetPhone() {
            try {
                this.errorMessage = '';

                const token = await this.$recaptcha.execute('phoneResetLink');

                await this.resetPhoneLink({ tz: this.tz, _token: token });

                this.isSent = true;
            } catch (error) {
                const { status } = error?.response;

                if (status !== StatusCodes.INTERNAL_SERVER_ERROR) {
                    this.errorMessage = 'מספר הזהות לא נמצא במערכת הסטודנטים';

                    return;
                }

                this.errorMessage =
                    'Something went wrong. Please try again later.';

                console.error(error);

                this.$sentry.captureException(error);
            }
        }
    }
};
</script>
