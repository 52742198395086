
<template>
    <div>
        <nuxt-link
            v-if="!isExternalLink(link.redirect)"
            :to="link.redirect"
            :target="link.blank ? '_blank' : ''"
        >
            {{ link.text }}
        </nuxt-link>
        <a v-else :href="link.redirect" :target="link.blank ? '_blank' : ''">
            {{ link.text }}
        </a>
    </div>
</template>

<script>
export default {
    props: {
        link: {
            type: Object,
            required: true
        }
    },

    methods: {
        isExternalLink(link) {
            return link.startsWith('http');
        }
    }
};
</script>
