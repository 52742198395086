export const defaultState = () => ({
    ads: {}
});

export const state = () => defaultState();

export const getters = {
    ads: state => state.ads,
    getByName: state => name => state.ads[name] || null
};

export const mutations = {
    ADD(state, ad) {
        state.ads = { ...state.ads, ...ad };
    },

    RESET(state) {
        Object.assign(state, defaultState());
    }
};

export const actions = {
    async fetch({ commit }, slot) {
        const ad = await this.$axios.$get(`/info-pictures/slots/${slot}`);

        commit('ADD', { [slot]: ad });
    },

    addView(context, adId) {
        return this.$axios.$post(`/info-pictures/${adId}/views`);
    },

    addClick(context, adId) {
        return this.$axios.$post(`/info-pictures/${adId}/clicks`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
