<template>
    <footer class="b_footer">
        <ad name="above-footer" />

        <div class="container">
            <div class="footer_section">
                <div class="footer_top flex">
                    <div class="mobile_logo_wrapper">
                        <nuxt-link
                            to="/"
                            class="link_logo"
                            aria-label="Logo wrapper, Homepage hyperlink"
                        >
                            <picture v-full-image-path:assets="'logo.svg'" />
                        </nuxt-link>
                    </div>

                    <campus-chooser />

                    <columns :columns="columns" />

                    <social-icons />
                </div>
                <div class="footer_bottom">
                    <div class="bottom_wrapper flex">
                        <div class="bottom_right_column flex">
                            <div class="logo_wrapper">
                                <nuxt-link
                                    to="/"
                                    class="link_logo"
                                    aria-label="Logo wrapper, Homepage hyperlink"
                                >
                                    <picture
                                        v-full-image-path:assets="'logo.svg'"
                                    />
                                </nuxt-link>
                            </div>
                            <div class="content_wrapper">
                                <div class="content">
                                    <p>
                                        אגודת הסטודנטים והסטודנטיות - הגב שלך
                                        בקמפוס
                                    </p>
                                    <p>
                                        כל הזכויות שמורות © לאגודת הסטודנטים
                                        והסטודנטיות בעברית
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="bottom_left_column">
                            <div class="developed flex">
                                <div class="content">
                                    פותח ע״י
                                </div>
                                <a
                                    href="https://www.prpl.io/?ref=aguda_footer"
                                    target="_blank"
                                    rel="noreferrer"
                                    class="developed_link"
                                    aria-label="PURPLE website hyperlink"
                                >
                                    <picture
                                        v-full-image-path:assets="'purple.svg'"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Ad from '@/components/ads';
import Columns from './footer-items/Columns';
import SocialIcons from './footer-items/SocialIcons';
import CampusChooser from './footer-items/CampusChooser';

export default {
    components: {
        Ad,
        Columns,
        SocialIcons,
        CampusChooser
    },

    data() {
        return {
            columns: []
        };
    },

    computed: {
        ...mapGetters({
            selectedCampus: 'campuses/selectedCampus'
        })
    },

    watch: {
        async selectedCampus(value) {
            if (!value) {
                return;
            }

            await this.getColumns();
        }
    },

    methods: {
        ...mapActions({
            fetchColumns: 'footerColumns/fetchColumns'
        }),

        async getColumns() {
            try {
                this.columns = await this.fetchColumns(this.selectedCampus.id);
            } catch (error) {
                this.columns = [];

                console.error(error);
            }
        }
    }
};
</script>
