export const defaultState = () => ({
    homepages: []
});

export const state = () => defaultState();

export const getters = {
    getContentByCampusId: state => campusId =>
        state.homepages.find(item => item.campusId === campusId)
};

export const mutations = {
    ADD_SINGLE_HOMEPAGE(state, homepage) {
        state.homepages = [...state.homepages, homepage];
    }
};

export const actions = {
    async getHomepage({ commit, getters }, campusId) {
        if (!campusId) {
            const selectedCampus = this.$cookies.get('selectedCampus');

            if (selectedCampus) {
                const { id } = JSON.parse(selectedCampus);

                campusId = id;
            }
        }

        if (campusId) {
            let homepage = getters.getContentByCampusId(campusId);

            if (!homepage) {
                homepage = await this.$axios.$get(
                    `/campuses/${campusId}/homepage`
                );
            }

            commit('ADD_SINGLE_HOMEPAGE', homepage);

            return homepage;
        }

        return this.$axios.$get('/homepages/random');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
