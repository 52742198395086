<template>
    <div class="left_column flex">
        <div v-click-outside="hideSearch" class="search_wrappper">
            <div class="search_form">
                <form @submit.prevent="onSubmit">
                    <input
                        v-model="q"
                        class="form_control"
                        type="text"
                        placeholder="מה תרצה לחפש?"
                    />
                    <button class="btn_search" type="submit" />
                </form>
            </div>
        </div>
        <div class="btn_open_search">
            <button
                id="openSearchButton"
                class="btn_toggle_search jsSearchToggle"
                type="button"
                aria-label="Toggle search input"
                @click="openSearch"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            q: ''
        };
    },

    methods: {
        ...mapActions({
            initJQuery: 'scripts/initJQuery'
        }),

        openSearch() {
            this.initJQuery();

            const $ = window.jQuery;

            $('.search_wrappper').slideToggle(200);
        },

        hideSearch({ target: { id } }) {
            if (id === 'openSearchButton') {
                return;
            }

            this.initJQuery();

            const $ = window.jQuery;

            $('.search_wrappper').slideUp(200);
        },

        onSubmit() {
            if (!this.q) {
                return;
            }

            this.$router.push(`/search?q=${this.q}`);
        }
    }
};
</script>
