export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index() {
        return this.$axios.get('/credit-cards');
    },

    delete(ctx, id) {
        return this.$axios.delete(`/credit-cards/${id}`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
