export const defaultState = () => ({
    STATUS_CANCELLED: 'cancelled'
});

export const state = () => defaultState();

export const getters = {
    STATUS_CANCELLED: state => state.STATUS_CANCELLED
};

export const mutations = {};

export const actions = {
    index(
        ctx,
        { page = 1, perPage = 7, search = '', filter = '', order = 'ASC' }
    ) {
        const params = {
            page,
            order,
            filter,
            perPage,
            q: search,
            sortBy: 'date'
        };

        return this.$axios.$get('/events', { params });
    },
    show(ctx, id) {
        return this.$axios.$get(`/events/${id}`);
    },

    details(ctx, id) {
        return this.$axios.$get(`/events/${id}/details`);
    },

    join(ctx, data) {
        return this.$axios.$post(`/events/${data.id}`, data);
    },

    cancel(ctx, id) {
        return this.$axios.$post(`/events/${id}/cancel`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
