export const defaultState = () => ({
    isMenuOpened: false
});

export const state = () => defaultState();

export const getters = {
    loggedUser: state => state.auth && state.auth.user,
    userInitials: (state, getters) => {
        const { loggedUser } = getters;

        if (!loggedUser) {
            return '';
        }

        const { firstName, lastName } = loggedUser;

        return firstName.charAt(0) + lastName.charAt(0);
    },
    isPremiumUser: (state, getters) =>
        getters.loggedUser &&
        getters.loggedUser.roles.some(
            role => role.name === state.roles.ROLE_AGUDA
        ),
    isMenuOpened: state => state.isMenuOpened
};

export const mutations = {
    SET_IS_MENU_OPENED(state, val) {
        state.isMenuOpened = val;
    }
};

export const actions = {
    async nuxtServerInit({ dispatch }) {
        await Promise.all([
            dispatch('contents/index'),
            dispatch('campuses/index')
        ]);
    }
};
