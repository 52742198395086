export const defaultState = () => ({
    MARATHON: 'marathon',
    ORDER: 'order',
    EVENT: 'event',
    PAYMENT_STARS: 'stars',
    PAYMENT_CREDIT: 'credit',
    PAYMENT_CREDIT_AND_STARS: 'credit&stars',
    PAYMENT_FREE: 'free'
});

export const state = () => defaultState();

export const getters = {
    ORDER: state => state.ORDER,
    EVENT: state => state.EVENT,
    MARATHON: state => state.MARATHON,
    PAYMENT_STARS: state => state.PAYMENT_STARS,
    PAYMENT_CREDIT: state => state.PAYMENT_CREDIT,
    PAYMENT_FREE: state => state.PAYMENT_FREE,
    ALL_TYPES: state => [state.MARATHON, state.ORDER],
    PAYMENT_CREDIT_AND_STARS: state => state.PAYMENT_CREDIT_AND_STARS
};

export const mutations = {};

export const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
};
