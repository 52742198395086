'use strict';

export const defaultState = () => ({
    PENDING: {
        value: 'pending',
        text: 'ממתין לתגובה'
    },
    ACCEPTED: {
        value: 'accepted',
        text: 'אושר'
    },
    REJECTED: {
        value: 'rejected',
        text: 'נדחה'
    },
    RESERVE_TYPE: {
        value: 'reserve',
        text: 'מילואים',
        title: 'מילואימניקים',
        description:
            'על כל יום מילואים מאושר בשנת הלימודים (שאינו בסוף שבוע, חג או חופשה) תוכלו לצבור כוכבים ולאחר מכן להמיר להטבות אקדמיות כמפורט מטה. ההטבות יסייעו לכם לחזור לקצב הלימודים ולא לפגר מאחור.'
    },
    MOURNING_TYPE: {
        value: 'mourning',
        text: 'אבל במשפחה',
        title: 'אבל במשפחה',
        description:
            'אבל מדרגה ראשונה (אם, אב, אחים, ילדים, ובני זוג). זכאים ל- 10 כוכבים.'
    },
    BIRTH_TYPE: {
        value: 'birth',
        text: 'הורים טריים',
        title: 'הורים טריים',
        description:
            'אמהות ואבות טריים? קודם ומה לגבי כל, מזל טוב!!! אנו שמחים להעניק לכם​ אמהות זכאיות ל- 100 כוכבים ואבות ל- 50 כוכבים.'
    }
});

export const state = () => defaultState();

export const getters = {
    PENDING: state => state.PENDING,
    ACCEPTED: state => state.ACCEPTED,
    REJECTED: state => state.REJECTED,
    ALL_STATUSES: state => [state.PENDING, state.ACCEPTED, state.REJECTED],
    RESERVE_TYPE: state => state.RESERVE_TYPE,
    MOURNING_TYPE: state => state.MOURNING_TYPE,
    BIRTH_TYPE: state => state.BIRTH_TYPE,
    types: state => [state.RESERVE_TYPE, state.MOURNING_TYPE, state.BIRTH_TYPE]
};

export const mutations = {};

export const actions = {
    index(ctx, { page = 1, perPage = 10 }) {
        const params = {
            page,
            perPage
        };

        return this.$axios.$get('/requests', {
            params
        });
    },

    store(ctx, data) {
        return this.$axios.$post('/requests', data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
