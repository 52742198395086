'use strict';

export const defaultState = () => ({
    QUIZ: 'quiz',
    TEST: 'test',
    SUMMARY: 'summary',
    OTHER: 'other',
    INVALID: {
        value: 'invalid',
        text: 'קובץ לא ראוי'
    },
    DAMAGED: {
        value: 'damaged',
        text: 'קובץ פגום'
    },
    COPYRIGHT: {
        value: 'copyright',
        text: 'קובץ מפר זכויות יוצרים'
    },
    WRONG_PLACE: {
        value: 'misplaced',
        text: 'קובץ לא במקום הנכון'
    },
    REPORT_OTHER: {
        value: 'other',
        text: 'אחר'
    }
});

export const state = () => defaultState();

export const getters = {
    QUIZ: state => state.QUIZ,
    TEST: state => state.TEST,
    SUMMARY: state => state.SUMMARY,
    OTHER: state => state.OTHER,
    FILE_TYPES: state => [
        {
            text: 'מבחנים',
            formText: 'מבחן',
            value: state.TEST,
            icon: 'icon_nav_tests.svg'
        },
        {
            text: 'בחנים',
            formText: 'בוחן',
            value: state.QUIZ,
            icon: 'icon_nav_exams.svg'
        },
        {
            text: 'סיכומים',
            formText: 'סיכום',
            value: state.SUMMARY,
            icon: 'icon_nav_summaries.svg'
        },
        {
            text: 'שונות',
            formText: 'שונות',
            value: state.OTHER,
            icon: 'icon_nav_various.svg'
        }
    ],

    REPORT_TYPES: state => [
        state.INVALID,
        state.DAMAGED,
        state.WRONG_PLACE,
        state.COPYRIGHT,
        state.REPORT_OTHER
    ]
};

export const mutations = {};

export const actions = {
    totalCount() {
        return this.$axios.$get('/files/total-count');
    },

    report(ctx, { id, ...data }) {
        return this.$axios.$post(`files/${id}/report`, data);
    },

    comments(ctx, id) {
        return this.$axios.$get(`files/${id}/comments`);
    },

    store(ctx, fileData) {
        return this.$axios.post('files', fileData);
    },

    vote(ctx, { id, isUpvoted }) {
        return this.$axios.$post(`files/${id}/vote`, { isUpvoted });
    },

    delete(ctx, id) {
        return this.$axios.$delete(`files/${id}`);
    },

    favorite(ctx, id) {
        return this.$axios.$post(`files/${id}/favorite`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
