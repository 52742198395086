<template>
    <div v-if="$showModal('resetPhone')" class="custom_popup_wrapper">
        <div class="custom_popup_inner">
            <div class="b_modal xs_size mfp-hide zoom-anim-dialog">
                <div class="modal_content_inner">
                    <div>
                        <div
                            class="modal_title second_font text_center title_decor"
                        >
                            אפס את הטלפון
                        </div>
                        <div v-if="!isCompleted" class="form_wrapper">
                            <form @submit.prevent="resetPhoneHandler">
                                <div class="rows_wrapper">
                                    <div class="form_row">
                                        <label class="form_label">
                                            הזנ/י מספר טלפון
                                        </label>
                                        <input
                                            v-model="formData.phone"
                                            type="number"
                                            class="form_control hide-arrows"
                                            :class="{
                                                'is-invalid': hasError('phone')
                                            }"
                                            @input="clearServerErrors"
                                        />
                                        <div class="invalid-feedback">
                                            {{ getError('phone') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="rows_wrapper">
                                    <div class="form_row">
                                        <label class="form_label">
                                            חזור על מספר טלפון
                                        </label>
                                        <input
                                            v-model="formData.phoneConfirmation"
                                            type="number"
                                            class="form_control hide-arrows"
                                            :class="{
                                                'is-invalid': hasError(
                                                    'phoneConfirmation'
                                                )
                                            }"
                                            @input="clearServerErrors"
                                        />
                                        <div class="invalid-feedback">
                                            {{ getError('phoneConfirmation') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="btn_wrapper">
                                    <button
                                        type="submit"
                                        class="btn btn_green"
                                        :disabled="isProcessing"
                                    >
                                        התחבר/י
                                    </button>
                                </div>
                                <div v-if="errorMessage" class="error-box">
                                    {{ errorMessage }}
                                </div>
                            </form>
                        </div>
                        <div v-else class="success-box">
                            מספר הטלפון עודכן, נסו להירשם עכשיו
                        </div>
                    </div>

                    <recaptcha-message />
                </div>

                <button
                    title="Close (Esc)"
                    type="button"
                    class="mfp-close"
                    @click="close"
                >
                    ×
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { StatusCodes as HTTP } from 'http-status-codes';
import { required, sameAs } from 'vuelidate/lib/validators';
import recaptchaMixin from '@/mixins/recaptcha.mixin.js';

export default {
    validations: {
        formData: {
            phone: { required },
            phoneConfirmation: { required, sameAs: sameAs('phone') }
        }
    },

    mixins: [recaptchaMixin],

    data() {
        const { token = '' } = this.$route.query;

        return {
            isProcessing: false,
            formData: {},
            serverErrors: [],
            token,
            isCompleted: false,
            errorMessage: ''
        };
    },

    methods: {
        ...mapActions({
            resetPhone: 'account/resetPhone'
        }),

        async resetPhoneHandler() {
            this.serverErrors = [];
            this.errorMessage = '';

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                this.isProcessing = true;

                const recaptchaToken = await this.$recaptcha.execute(
                    'phoneReset'
                );

                await this.resetPhone({
                    token: this.token,
                    phone: this.formData.phone,
                    _token: recaptchaToken
                });

                this.isCompleted = true;
            } catch (error) {
                const { response } = error;

                if (response) {
                    const { data, status } = response;

                    if (status === HTTP.BAD_REQUEST && data.errors) {
                        this.serverErrors = data.errors;

                        this.$toasted.error('אנא בדקו את הערכים בטופס');

                        return;
                    }
                }

                this.errorMessage = 'לינק לא תקף';

                console.error(error);

                this.$sentry.captureException(error);
            } finally {
                this.isProcessing = false;
            }
        },

        close() {
            this.resetForm();

            this.$closeModal();
        },

        resetForm() {
            this.formData = {};
            this.$v.$reset();
            this.clearServerErrors();
            this.errorMessage = '';
        },

        hasError(key) {
            return (
                this.$v.formData[key].$error ||
                this.serverErrors.find(error => error.param === key)
            );
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.$v.formData,
                this.serverErrors,
                key
            );
        },

        clearServerErrors() {
            this.serverErrors = [];
        }
    }
};
</script>
