<template>
    <div class="menu_body_inner flex jsTabs">
        <div class="navigation_wrapper">
            <div class="tabs_navigation second_font">
                <div
                    v-for="(section, sectionIndex) in sections"
                    :key="sectionIndex"
                    class="tab_item"
                    :class="{
                        '-active': selectedSectionIndex === sectionIndex
                    }"
                    @click="setSelectedSection(sectionIndex)"
                >
                    {{ section.title }}
                </div>
            </div>
        </div>
        <div class="main_menus_wrapper">
            <div
                v-for="(section, index) in sections"
                :key="index"
                class="main_menu_row jsTabsItem"
                :class="{
                    '-active -fade': selectedSectionIndex === index
                }"
            >
                <div class="mobile_wrap_main_menu">
                    <div class="menus_wrapper_inner flex">
                        <items :selected-menu="selectedMenu" />
                        <articles :articles="selectedMenu.articles" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Items from './menu-items/Items';
import Articles from './menu-items/Articles';

export default {
    components: {
        Items,
        Articles
    },

    props: {
        menuItems: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            selectedSectionIndex: 0
        };
    },

    computed: {
        ...mapGetters({
            isMenuOpened: 'isMenuOpened'
        }),

        selectedMenu() {
            return this.menuItems[this.selectedSectionIndex];
        },

        sections() {
            return this.menuItems.map(({ title, order }) => ({
                title,
                order
            }));
        }
    },

    watch: {
        isMenuOpened() {
            if (!this.isMenuOpened) {
                this.selectedSectionIndex = 0;
            }
        }
    },

    methods: {
        setSelectedSection(index) {
            this.selectedSectionIndex = index;
        }
    }
};
</script>
